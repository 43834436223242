import I18n from "i18n-js";
import translation from "./en.json";
import translationPt from "./pt.json";

I18n.translations = { pt: translationPt.pt, en: translation.en };

I18n.fallbacks = true;
I18n.defaultLocale = "en";
I18n.locale = (() => window.locale || "en")();

export const t = I18n.t;
export const toHumanSize = I18n.toHumanSize;

export default I18n;
